import React from 'react';
import ReactDOM from 'react-dom';
import Glider from 'glider-js';
import isEqual from 'underscore/modules/isEqual';
import debounce from 'underscore/modules/debounce';
import 'jquery-zoom';
import { subscribe } from '@state/store';
import { mapProductMedia } from './map-product-media';
import { PinchZoom } from './PinchZoom';

XA.component.productMediaCarousel = (() => {
  const api = {
    windowResizeTimer: null,
    initialized: false,
    mediaGliderRef: null,
    thumbnailsGliderRef: null,
    templates: {
      labels: labels => {
        return `
          <div class="labels">
            ${labels.map(label => `<div class="label _${label.code}">${label.value}</div>`)}
          </div>
        `;
      },
      image: (mediaItem, alt) => {
        return `
          <div class="product-image" data-bind-event="mouseenter:handleHoverZoom">
            <picture data-selector="media-carousel-product-image">
              <source srcset="${mediaItem.srcset.items[1].src}" media="(min-width: 554px)">
              <source srcset="${mediaItem.srcset.items[2].src}" media="(min-width: 768px)">
              <source srcset="${mediaItem.srcset.items[3].src}" media="(min-width: 992px)">
              <img src="${mediaItem.srcset.items[1].src}" alt="${alt ?? ''}">
            </picture>
          </div>
        `;
      },
      video: mediaItem => {
        return `
          <iframe
            ${mediaItem.src.indexOf('youtu') > -1 ? 'data-youtube' : 'data-vimeo'}
            width="100%"
            height="420"
            src="${mediaItem.src}?enablejsapi=1"
            frameborder="0"
            allowfullscreen=""
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          >
          </iframe>
        `;
      },
      buttonLeft: () => {
        return ` 
        <div class="slide-button">
          
        <button
          role="button"
          type="button"
          class="d-none d-md-block btn btn-cta-secondary-2"
          data-selector="glider-prev"
        >
          <span class="sr-only">{{ o_model.phrases.previous }}</span>
          <span class="fa fa-angle-left" aria-hidden="true"></span>
        </button>
      </div>`;
      },
      buttonRight:()=>{
        return ` <div class="slide-button">
        <button
          role="button"
          type="button"
          class="d-none d-md-block btn btn-cta-secondary-2"
          data-selector="glider-next"
        >
          <span class="sr-only">{{ o_model.phrases.next }}</span>
          <span class="fa fa-angle-right" aria-hidden="true"></span>
        </button>
      </div>`;
      },
      thumbnail: (mediaItem, alt) => {
        return mediaItem.type === 'Image'
          ? `<div class="slide-item">
            <img
              class="slide-item-thumbnail"
              src="${mediaItem.thumbnail}"
              alt="${alt ?? ''}"
              data-bind-event="mouseup:handleThumbnailClick"
              width="80"
              height="80"
              loading="auto"
              >
          </div>`
          : `<div class="slide-item d-none d-md-grid">
            <span
              class="slide-item-thumbnail media-control fa fa-2x fa-play"
              data-bind-event="mouseup:handleThumbnailClick"
            ></span>
          </div>
        `;
      }
    },

    // TODO: Abstract to central location
    get breakpoint() {
      switch(true) {
      case window.matchMedia('(min-width: 1240px)').matches:
        return 'xl';
      case window.matchMedia('(min-width: 992px)').matches:
        return 'lg';
      case window.matchMedia('(min-width: 768px)').matches:
        return 'md';
      case window.matchMedia('(min-width: 544px)').matches:
        return 'sm';
      default:
        return 'xs';
      }
    },

    bindEvents() {
      const components = document.querySelectorAll('.product-media-carousel');
      components.forEach(component => {
        const nodesWithEvents = component.querySelectorAll('[data-bind-event]:not([data-event-bound])');

        nodesWithEvents.forEach(node => {
          const [type, handler] = node.dataset?.bindEvent?.split(':') || [];

          if (typeof this[handler] === 'function') {
            node.dataset.eventBound = true;
            node.addEventListener(type, (event) => this[handler].call(this, event));
          }
        });
      });
    },


    thumbnailGlider() {

      if (!['md', 'lg', 'xl'].includes(api.breakpoint)) {
        return;
      }

      const thumbnailsContainer = document.querySelector('[data-selector=media-carousel-thumbnail-slider]');
      if (!thumbnailsContainer) {
        return;
      }

      const glider = thumbnailsContainer.querySelector('[data-selector=thumbnails]');

      if (!glider) {
        return;
      }

      api.thumbnailsGliderRef = new Glider(glider, {
        slidesToScroll: 3,
        slidesToShow: 6,
        itemWidth: 94,
        exactWidth: true,
        arrows: {
          prev: thumbnailsContainer.querySelector('[data-selector=glider-prev]'),
          next: thumbnailsContainer.querySelector('[data-selector=glider-next]')
        }
      });
    },


    mediaGlider() {
      const imageContainer = document.querySelector('[data-selector=media-carousel-media-slider]');
      const glider = imageContainer ? imageContainer.querySelector('.glider-contain') : null;

      if (!glider) {
        return;
      }

      api.mediaGliderRef = new Glider(glider, {
        scrollLock: true,
        scrollLockDelay: 0,
        slidesToScroll: 1,
        slidesToShow: 1,
        itemWidth: 210,
        draggable: !['md', 'lg', 'xl'].includes(api.breakpoint),
        dots: document.querySelector('.dots'),
        responsive: [{
          breakpoint: 775,
          settings: {
            dots: null,
            itemWidth: 480
          }
        }]
      });
    },

    bindZoom(slide) {
      if(!slide) {
        return;
      }

      if(!['md', 'lg', 'xl'].includes(api.breakpoint)) {
        return;
      }

      if(slide.dataset.zoom) {
        return;
      }

      slide.dataset.zoom = 'true';
      const zoomUrl = $(slide).find('[media="(min-width: 992px)"]').attr('srcset');

      $(slide).find('img')
        .wrap('<span style="display: flex; width: 100%; justify-content: center;"></span>')
        .css('display', 'block')
        .parent()
        .zoom({
          url: zoomUrl,
          callback: () => $(slide).trigger('mouseenter').trigger('mousemove'),
          onZoomIn: () => {
            $(slide).find('img:not(.zoomImg)').css({ opacity: 0 });
          },
          onZoomOut: () => {
            $(slide).find('img:not(.zoomImg)').css({ opacity: 1 });
          }
        });
    },

    handleFullScreenOpen() {
      const index = api.mediaGliderRef.slide;
      // eslint-disable-next-line max-len
      const current = document.querySelector(`[data-selector=media-carousel-media-slider] [data-gslide="${index}"] [media="(min-width: 992px)"]`);

      const container = document.createElement('div');
      container.id = 'portal';
      document.body.appendChild(container);

      const closePortal = () => {
        ReactDOM.unmountComponentAtNode(container);
        container.remove();
      };

      const breakpointSize = ['md', 'lg', 'xl'].includes(api.breakpoint) ? 'large' : 'small';
      const App = (<PinchZoom imgUrl={current.srcset} onClose={closePortal} breakpointSize={breakpointSize} />);

      const rootElement = document.getElementById('portal');
      rootElement && ReactDOM.render(App, rootElement);
    },


    handleThumbnailClick(event) {
      const thumbnail = event.target;
      const index = thumbnail?.parentNode?.dataset?.gslide || 0;
      api.mediaGliderRef.scrollItem(index, true);

      document.querySelectorAll('.product-thumbnails .active').forEach(activeElement => activeElement.classList.remove('active'));
      thumbnail.classList.add('active');
    },


    handleGliderSlideVisible(event) {
      const isArrowClick = event.target.classList.contains('thumbnail-slider');
      const index = event.detail.slide || 0;
      const slide = $('[data-selector=media-carousel-media-slider]').find(`[data-gslide="${index}"]`);
      const isYoutube = slide.attr('data-youtube') !== undefined;
      const isVimeo = slide.attr('data-vimeo') !== undefined;

      if (isArrowClick) {
        return;
      }


      api.bindZoom(slide[0]);

      if (isYoutube) {
        const player = $(slide).get(0);
        player.contentWindow.postMessage(JSON.stringify({ event: 'command', 'func': 'playVideo' }), '*');
      }

      if (isVimeo) {
        const player = $(slide).get(0);
        player.contentWindow.postMessage(JSON.stringify({ method: 'play', value: true }), '*');
      }
    },


    handleGliderSlideHidden(event) {
      const isArrowClick = event.target.classList.contains('thumbnail-slider');
      const index = event.detail.slide || 0;
      const slide = $('[data-selector=media-carousel-media-slider]').find(`[data-gslide="${index}"]`);
      const isYoutube = slide.attr('data-youtube') !== undefined;
      const isVimeo = slide.attr('data-vimeo') !== undefined;

      if (isArrowClick) {
        return;
      }

      if (isYoutube) {
        const player = $(slide).get(0);
        player.contentWindow.postMessage(JSON.stringify({ event: 'command', 'func': 'pauseVideo' }), '*');
      }

      if (isVimeo) {
        const player = $(slide).get(0);
        player.contentWindow.postMessage(JSON.stringify({ method: 'pause', value: true }), '*');
      }
    },

    bindMediaGliderEvents() {
      document.removeEventListener('glider-slide-visible', api.handleGliderSlideVisible);
      document.removeEventListener('glider-slide-hidden', api.handleGliderSlideHidden);

      document.addEventListener('glider-slide-visible', api.handleGliderSlideVisible);
      document.addEventListener('glider-slide-hidden', api.handleGliderSlideHidden);
    },


    init() {
      api.mediaGlider();
      api.thumbnailGlider();
      api.bindMediaGliderEvents();
      api.bindEvents();

      window.addEventListener('orientationchange', () => {
        api.thumbnailGlider();
      });

      window.addEventListener('resize', debounce(() => {
        api.thumbnailGlider();
      }, 250));


      subscribe('productDetail', (state, prevState) => {
        const product = mapProductMedia(state.productDetail);
        const media = product.variant.productMedia;
        const prevMedia = mapProductMedia(prevState.productDetail).variant.productMedia;
        const labels = state.productDetail.labels;

        if(!media || isEqual(media, prevMedia)) {

          // Image are the same, or no images at all
          return;
        }

        api.mediaGliderRef?.destroy();
        api.thumbnailsGliderRef?.destroy();

        const slidesHtml = media.map(mediaItem =>
          mediaItem.type === 'Video'
            ? api.templates.video(mediaItem)
            : api.templates.image(mediaItem, product.variant.title)
        ).join('\n');

        const $imageContainer = $('[data-selector=media-carousel-media-slider]');
        $imageContainer.html(`<div class="glider-contain">${slidesHtml}</div>`);

        const thumbnailsHtml = media.map(mediaItem => api.templates.thumbnail(mediaItem, product.variant.title)).join('\n');
        const $thumbnaislContainer = $('[data-selector=media-carousel-thumbnail-slider]');
        const imageCount = media.length;
        const buttonLeft = api.templates.buttonLeft();
        const buttonRight = api.templates.buttonRight();
        $thumbnaislContainer.html(
          `
          ${imageCount > 6 ? buttonLeft : ''}
  <div class="d-flex flex-direction-row thumbnail-slider" data-selector="thumbnails">
            ${thumbnailsHtml}
            </div>
            ${imageCount > 6 ? buttonRight : ''}
           
          `
        );



        if(labels?.length > 0) {
          const labelsHtml = api.templates.labels(labels);
          $imageContainer.prepend(labelsHtml);
        }

        api.mediaGlider();
        api.thumbnailGlider();
        api.bindMediaGliderEvents();
        api.bindEvents();

      });
    }
  };

  return api;
})();


XA.register('productMediaCarousel', XA.component.productMediaCarousel);
