/* global Vimeo */
XA.component.embeddedVideo = (($, document) => {
  const vimeoVideos = document.querySelectorAll('[data-vimeo-id]');

  const api = {
    initialized: false,

    initVimeoInstance(vimeoVideos) {
      $.getScript('https://player.vimeo.com/api/player.js', () => {

        const autoplay = vimeoVideos.length > 1;

        const options = {
          loop: true,
          responsive: true,
          muted: autoplay,
          autoplay
        };

        // Looping <NodeList> not <Array>
        Array.prototype.forEach.call(vimeoVideos, video => {
          const player = new Vimeo.Player(video, options);
          player.play();
        });
      });
    },

    init() {
      if(this.api.initialized === true) {
        return;
      }

      this.api.initialized = true;

      if(vimeoVideos?.length > 0) {
        this.api.initVimeoInstance(vimeoVideos);
      }

      $(document).ready(function () {
        const youTubeVideos = document.querySelectorAll('[data-youtube-id]');
        if (youTubeVideos && youTubeVideos.length > 0) {
          Array.prototype.forEach.call(youTubeVideos, video => {
            video.src = video.dataset.src;
          });
        }
      });
    }
  };

  return api;

})(jQuery, document);
XA.register('embeddedVideo', XA.component.embeddedVideo);
