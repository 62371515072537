XA.component.productSymbols = (function($) {
  const api = {};

  api.init = function() {
    $(document).on('show.bs.modal', '#mdlSymbolModal', function (e) {
      const url = e.relatedTarget.href;

      if (!url || url === '') {
        $(this).modal('hide');
        return;
      }

      const $modal = $('#mdlSymbolModal');
      $modal.find('.modal-title').text(e.relatedTarget.title);

      const iframe = document.querySelector('#mdlSymbolModal iframe');

      // Setting src on an existing iframe creates a history entry
      const frame = iframe.cloneNode();
      frame.src = url;
      frame.style.height = window.innerHeight - 200;
      frame.height = window.innerHeight - 200;
      iframe.parentNode.replaceChild(frame, iframe);

    })
      .on('hidden.bs.modal', '#mdlSymbolModal', function () {
        const $modal = $('#mdlSymbolModal');
        // $modal.find('iframe.modal-body').attr('src', 'about:blank');
        $modal.find('.modal-title').text('');
      });
  };

  return api;
})(jQuery);

XA.register('productSymbols', XA.component.productSymbols);
