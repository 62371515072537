import React from 'react';
import { render, createPortal } from 'react-dom';
import store from '@state/store';
import AlternativeProductModal from './ProvideAlternativeProductModal';

XA.component.AlternativeProductModal = (function ($, document) {
  const api = {
    initialized: false,

    bindEvents() {
      const component = document.querySelector('.component.button-alternative-product');

      const nodesWithEvents = component?.querySelectorAll('[data-bind-event]:not([data-event-bound])') || [];
      nodesWithEvents.forEach(node => {
        const [type, handler] = node.dataset?.bindEvent?.split(':') || [];
        if (typeof this[handler] === 'function') {
          node.dataset.eventBound = true;
          node.addEventListener(type, (event) => this[handler].call(this, event));
        }
      });
    },


    openAlternativeProductModal() {
      const action = {
        type: 'modal/openAlternativeProductModal'
      };

      store.dispatch(action);
    },

    init: () => {
      const mountNode = document.querySelector('#portal') || null ;
      if (mountNode) {
        render(createPortal(<AlternativeProductModal />, mountNode), document.createElement('div'));
      }

      api.bindEvents();
    }
  };
  return api;
})(jQuery, document);

XA.register('AlternativeProductModal', XA.component.AlternativeProductModal);

