import { Swiper, Navigation } from 'swiper';

XA.component.latestBlog = (function () {
  const api = {
    initialized: false,
    init() {
      const blogTileContainers = document.querySelectorAll('.blog-tile-container') || [];

      blogTileContainers.forEach(list => {
        const swiperElement = list;//.querySelector('.swiper-wrapper');
        const nextEl = list.querySelector('.btn-list-slide.next');
        const prevEl = list.querySelector('.btn-list-slide.prev');
        swiperElement && new Swiper(swiperElement, {
          modules: [Navigation],
          slidesPerView: 1,
          spaceBetween: 16,
          breakpoints: {
            768: {
              slidesPerView: 2
            },
            1240: {
              slidesPerView: 3
            }
          },
          navigation: {
            nextEl: nextEl,
            prevEl: prevEl,
            lockClass: 'd-none',
            disabledClass: 'disabled'
          }
        });
      });
    }
  };

  return api;

})(jQuery, document);

XA.register('latestBlog', XA.component.latestBlog);
