import store from '@state/store';
import { setPreference } from './preferencesSlice';

XA.component.preferences = (function () {
  const api = {
    initialized: false,

    set(key, value) {
      store.dispatch(setPreference(key, value));
    },

    get(key) {
      return store.getState().preferences[key] || undefined;
    },

    init() {

    }
  };

  return api;

})(jQuery, document);

XA.register('preferences', XA.component.preferences);
