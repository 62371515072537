import { getBreakpoint } from '@utils/getBreakpoint';

/**
 * Component Navigation
 * @module Navigation
 * @param  {jQuery} $ Instance of jQuery
 * @return {Object} List of navigation methods
 */
XA.component.StoreViewMegaMenu = (function($) {
  const api = {
    initialiazed: false,


    bindEvents() {
      const components = document.querySelectorAll('[data-component="StoreViewMegaMenu"]');
      components?.forEach(component => {
        const nodesWithEvents = component.querySelectorAll('[data-bind-event]:not([data-event-bound])') || [];
        nodesWithEvents.forEach(node => {
          const [type, handler] = node.dataset?.bindEvent?.split(':') || [];

          if (typeof this[handler] === 'function') {
            node.dataset.eventBound = true;
            node.addEventListener(type, (event) => this[handler].call(this, event));
          }
        });
      });
    },


    toggleStoreViewMegaMenu(e) {
      e.preventDefault();
      e.stopPropagation();

      const $this = $(e.currentTarget);
      const $component = $('[data-component="StoreViewMegaMenu"]');
      $this.toggleClass('is-open');
      $this.find('.fa').toggleClass('fa-chevron-down fa-chevron-up');
      $component.find('.nav-overlay').toggleClass('active');

      const $megaMenu = $component.find('.mega-menu');
      $('.mega-menu-container').toggleClass('is-open');
      $megaMenu.toggleClass('is-open');
      $megaMenu.find('.mega-menu-item').first().addClass('is-open');
      document.body.classList.add('no-scroll-md');
    },


    closeStoreViewMegaMenu(e) {
      /* Also called programatically without event */
      e?.preventDefault();
      e?.stopPropagation();
      document.body.classList.remove('no-scroll-md');
      const $component = $('[data-component="StoreViewMegaMenu"]');
      $component.find('.is-open').removeClass('is-open');
      $component.removeClass('is-open');
      $component.find('.fa-chevron-up').removeClass('fa-chevron-up').addClass('fa-chevron-down');
      $component.find('.nav-overlay').removeClass('active');

      return false;
    },


    toggleMegaMenuSubMenu(e) {
      e.preventDefault();
      e.stopPropagation();
      const $this = $(e.currentTarget);

      $this.toggleClass('is-open');
      $this.closest('.submenu').siblings('.is-open').toggleClass('is-open');
      $this.closest('.submenu').find('.is-open').toggleClass('is-open');
      return false;
    },


    closeFullPageMegaMenuSubMenu(e) {
      e.preventDefault();
      e.stopPropagation();
      const $this = $(e.currentTarget).removeClass('is-open');

      $this.closest('.full-page').siblings('.is-open').removeClass('is-open');
      $this.closest('.full-page').find('.is-open').removeClass('is-open');
    },


    closeMegaMenuSubMenu(e) {
      // 1st level submenu is child of 'mega-menu-item'
      e.preventDefault();
      e.stopPropagation();
      const $this = $(e.currentTarget).toggleClass('is-open');

      $this.closest('.level1').siblings('.is-open').removeClass('is-open');
      $this.closest('.level1').find('.is-open').removeClass('is-open');
    },


    toggleMobileSubmenu(e) {
      e.preventDefault();
      e.stopPropagation();
      const $this = $(e.currentTarget).parents('a');
      const $component = $('[data-component="StoreViewMegaMenu"]');
      $this.toggleClass('is-open');
      $component.find('.nav-overlay').toggleClass('active');
      return false;
    },


    handleCategoryClick(e) {
      const isSmallScreen = ['xs', 'sm'].includes(getBreakpoint());
      const $this = $(e.currentTarget);

      if (!isSmallScreen && $this.parent().hasClass('with-subcategories')) {
        return true;
      }

      e.stopPropagation();
      e.preventDefault();
      $this.toggleClass('is-open');
      return false;
    },


    init() {
      api.bindEvents();

      const $component = $('[data-component="StoreViewMegaMenu"]');

      $component.find('.nav-overlay').on('mouseenter', (e) => {
        const isTouch = e.pointerType === 'touch';

        if(isTouch) {
          return;
        }

        api.closeStoreViewMegaMenu();
      });


      $(document).find('a[aria-haspopup]').on('click', e => {
        const isSmallScreen = ['xs', 'sm'].includes(getBreakpoint());

        if ($(e.currentTarget).parent().hasClass('with-subcategories')) {
          return true;
        }

        // On desktop links should be clickable and load a page
        // on mobile this would break the menu
        if(isSmallScreen) {
          e.preventDefault();
        }

      });

      $component.find('.mega-menu-item').on('mouseenter', function(e) {

        const isTouch = e.pointerType === 'touch';

        if (isTouch) {
          return;
        }

        $('.mega-menu-item').each(function() {
          $(this).find('.is-open').removeClass('is-open');
          $(this).removeClass('is-open');
        });

        $(this).addClass('is-open');
      });
    }
  };

  return api;
})(jQuery, document);
XA.register('StoreViewMegaMenu', XA.component.StoreViewMegaMenu);
