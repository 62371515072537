// TODO: Replace with a more permanent solution
$(document).ready(function() {
  $('.search-box').on('click', function() {
    const val = $('[name=textBoxSearch]').val();
    if(!val) {
      return;
    }
    const url = new URL(document.location.href);
    const params = new URLSearchParams(`s=${val}`);
    url.search = params;
    url.pathname = '/webshop';
    document.location.href = url.href;
  });
});
