XA.component.loginForm = (function ($, window) {
  const api = {
    $elForm: null,
    $elErrors: null
  };

  api.init = function () {
    api.$elForm = $('.component.login-button div[action][method], .component.login-button form');
    if(api.$elForm && api.$elForm.prop('tagName') === 'DIV') {
      $('input', api.$elForm).on('keypress', api.inputKeypress);
    }

    api.$elErrors = $('div.errors', api.$elForm);

    $('button[type=submit]', api.$elForm).on('click', api.submitClick);
  };

  api.inputKeypress = function(event) {
    if(event.keyCode === 13){
      event.preventDefault();

      api.$elForm.find('button[type=submit]').trigger('click');
    }
  };

  api.submitClick = function(event) {
    event.stopPropagation();
    const $button = $(event.currentTarget);

    api.$elErrors.hide();

    let data = {};
    if(api.$elForm.prop('tagName') === 'DIV') {
      data = $('input',api.$elForm).serialize();
    } else {
      data = api.$elForm.serialize();
    }

    if(api.validate()){
      $button.prepend('<span id="loginButton" class="fa fa-spinner fa-spin"></span>');
      $.ajax({
        method: api.$elForm.attr('method'),
        url: api.$elForm.attr('action'),
        data: data
      })
        .done(function (data/*, textStatus, jqXHR*/) {
          window.location.href = data.redirectUrl;
        })
        .fail(function(/* jqXHR, textStatus, errorThrown*/) {
          $('#loginButton').remove();
          api.$elErrors.show();
        });
    }

    return false;
  };

  api.validate = function() {
    let isValid = true;
    if(api.$elForm.prop('tagName') === 'DIV') {
      $('input',api.$elForm).each(function(){
        if(isValid) {
          isValid = this.checkValidity();
        } else {
          this.checkValidity();
        }
      });
    } else {
      isValid = api.$elForm[0].checkValidity();
    }

    // api.$elForm.addClass('was-validated');
    $('input',api.$elForm).each(function(){
      if(!this.validity.valid) {
        $(this).addClass('is-invalid');
      } else {
        $(this).removeClass('is-invalid');
      }
    });

    return isValid;
  };

  return api;
})(jQuery, window);

XA.register('loginForm', XA.component.loginForm);
