import imageService from '@services/image-service';

export function mapProductMedia(product) {
  const makeThumbnailUrl = urlString => {
    return imageService.getUrl(urlString, 0, 80, true);
  };

  const imageItems = product.variant.media.map(mediaItem => ({
    ...mediaItem,
    thumbnail: makeThumbnailUrl(mediaItem.thumbnail || mediaItem.src),
    srcset: {
      items: [
        { src: imageService.getUrl(mediaItem.src, 0, 210, true) },
        { src: imageService.getUrl(mediaItem.src, 0, 420, true) },
        { src: imageService.getUrl(mediaItem.src, 0, 1024, true) },
        { src: imageService.getUrl(mediaItem.src, 0, 2048, true) }
      ]
    }
  }));

  const videoItems = product.videos.map(video => ({
    type: 'Video',
    src: video.src
  }));


  const mappedData = ({
    ...product,
    variant: {
      ...product.variant,
      productMedia: [
        ...imageItems,
        ...videoItems
      ]
    }
  });

  return mappedData;
}
