import { setPreference } from '@components/Preferences/preferencesSlice';
import store from '@state/store';

XA.component.toggleTax = (() => {
  'use strict';

  const api = {
    initialized: false,

    bindeEvents(selectors) {
      const elementList = document.querySelectorAll(selectors);
      elementList .forEach(tile => {

        const nodesWithEvents = tile.querySelectorAll('[data-bind-event]:not([data-event-bound])');
        nodesWithEvents.forEach(node => {
          const [type, handler] = node.dataset?.bindEvent?.split(':') || [];
          if (typeof this[handler] === 'function') {
            node.dataset.eventBound = true;
            node.addEventListener(type, (event) => this[handler].call(this, event));
          }
        });
      });
    },


    toggleDisplayTax(e) {
      const status = e.target.checked;
      store.dispatch(setPreference({'displayTax': status}));
    },

    init() {
      const displayTax = !!store.getState().preferences.displayTax || false;
      const inputElement = document.querySelector('[data-component="toggleTax"] input[type=checkbox]');

      displayTax
        ? inputElement && inputElement.setAttribute('checked', 'checked')
        : inputElement && inputElement.removeAttribute('checked');

      api.bindeEvents('[data-component="toggleTax"]');
    }
  };

  return api;
})(jQuery, XA);


XA.register('toggleTAX', XA.component.toggleTax);
