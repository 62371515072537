import { getBreakpoint } from '@utils/getBreakpoint';

$(document).ready(function() {
  if(!['xs', 'sm'].includes(getBreakpoint())) {
    return;
  }

  const $components = $('.component.link-list.footer-navigation');

  $components.each(function(index) {
    const $component = $(this);
    const id = `collapse-${index}`;

    $component.find('h3').attr({
      'data-toggle': 'collapse',
      'data-target': `#${id}`,
      'aria-expanded' : 'false',
      'aria-controls': `${id}`
    });

    $component.find('ul').attr({
      id: id
    }).addClass('collapse');

    $component.find('.collapse').collapse({ toggle: false });
  });

});
