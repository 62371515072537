/* eslint-disable */
import React from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { useApi } from '@hooks/use-api';
import { Modal } from '@components-react/Modal/Modal';
import { selectUnitOfMeasure, selectVariant } from '../state/productDetailSlice';
import styles from './AlternativeProductModal.module.scss';

export const AlternativeProductModal = ({ alternativeProductNumber, onAddToCart, phrases }) => {
  const dispatch = useDispatch();
  const preferences = useSelector(state => state?.preferences);
  const selectedVariant = useSelector(state => selectVariant(state));
  const selectedUnitOfMeasure = useSelector(state => selectUnitOfMeasure(state));

  const { locale = 'nl-NL', displayTax = false } = preferences;

  const [{
    data: productData,
    error: fetchError,
    isLoading
  }] = useApi.get(`/products/${alternativeProductNumber}`);

  const dismissModal = () => {
    dispatch({
      type: 'modal/dismissModal'
    });
  };

  if(isLoading) {
    return <h1>Loading</h1>;
  }

  if(fetchError) {
    return (<Modal
      title={phrases.AlternativeProduct_Title}
      onModalClose={dismissModal}
      buttons={[{
        type: 'outline-primary',
        label: 'OK',
        onClick: dismissModal
      }]}
    >
      <p>{ phrases.AlternativeProduct_NoProducts }</p>
    </Modal>);
  }

  const alternativeVariant = productData.variants.find(variant => variant.code === productData.defaultVariantCode);
  const thumbnail = alternativeVariant?.media?.find(media => !!media.thumbnail);

  const currentPrice = displayTax
    ? selectedUnitOfMeasure.prices.current.incTax
    : selectedUnitOfMeasure.prices.current.exTax;

  const variantPrice = displayTax
    ? alternativeVariant.unitsOfMeasure[0].prices.current.incTax
    : alternativeVariant.unitsOfMeasure[0].prices.current.exTax;

  const discountPrice = currentPrice.numeric - variantPrice.numeric;

  const buttons = [{
    type: 'outline-primary',
    label: phrases.AlternativeProduct_Cancel,
    onClick: dismissModal
  }, {
    type: 'primary',
    label: phrases.AlternativeProduct_SelectAlternative,
    onClick: () => onAddToCart({ alternativeVariant })
  }];

  return (
    <Modal title={phrases.AlternativeProduct_Title} buttons={buttons} onModalClose={dismissModal}>
      <div className="d-flex flex-column">

        <div className={styles.product}>
          <h3 className="h3">{phrases.AlternativeProduct_YourChosenProduct}</h3>
          <div className="w-100">
            <div className="d-flex flex-row">
              {selectedVariant?.media[0]?.thumbnail && (
                <div className="mr-3">
                  <img src={selectedVariant?.media[0]?.thumbnail} className={styles.thumbnail} />
                </div>
              )}
              <div className="flex-direction-row">
                <h2 className="h3">{selectedVariant.title}</h2>
                <span className="text-sans-serif text-gray-500">{selectedVariant.code}</span>
              </div>
              <div className="h3 float-right ml-auto text-nowrap">
                {currentPrice.formatted}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.product}>
          <h3 className="h3 mb-1">{ phrases.AlternativeProduct_OurAlternative }</h3>
          <div className="w-100">
            <div className="d-flex flex-row">
              {thumbnail && <div className="mr-3"><img src={thumbnail} className={styles.thumbnail} /></div>}
              <div className="flex-direction-row">
                <h2 className="h3 mb-1">{alternativeVariant.title}</h2>
                <span className="text-sans-serif text-gray-500">{alternativeVariant.code}</span>
              </div>
              <div className="h3 float-right ml-auto text-nowrap">
                {variantPrice.formatted}
              </div>
            </div>
          </div>
        </div>

        <div className="align-self-end">
          <span className={`${styles.discountLabel} text-sans-serif text-basic float-right`}>
            {phrases.AlternativeProduct_YourBenefit}
          </span>
          <br />
          <span className={`${styles.discountPrice} text-sans-serif text-theme-1 text-bold float-right`}>
            {new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR' }).format(discountPrice)}
          </span>
        </div>

      </div>
    </Modal>
  );
};
