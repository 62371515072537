global.$ = global.jQuery = require('jquery');

require('popper.js');
require('bootstrap');

if (!/iPad|iPhone|iPod/.test(navigator.platform)) {
  $('a[href^=\'/\'][href$=\'.pdf\']').each(function() {
    const link = $(this);
    link.click(function(e) {
      e.stopPropagation();
      const modal = $(`<div class="pdf-modal modal fade" tabindex="-1" role="dialog">
                            <div class="modal-dialog modal-xl">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                                        <div class="modal-title" /></div>
                                    </div>
                                    <div class="modal-body" /></div>
                                </div>
                            </div>
                        </div>`);
      const modalBody = modal.find('.modal-body');
      const modalTitle = modal.find('.modal-title');
      $('body').append(modal);
      modalTitle.html(link.attr('title'));
      // eslint-disable-next-line max-len
      modalBody.html(`<object data="${link.attr('href')}" type="application/pdf" width="100%" height="${window.innerHeight - 200}px"><embed src="${link.attr('href')}" type="application/pdf" width="100%"/></object>`);
      modal.modal();
      modal.on('hidden', function() {
        $(this).remove();
      });
      return false;
    });
  });
}
