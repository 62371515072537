import React from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { addToCart, removeFromCartByVariantUom } from '@components-react/Cart/cartSlice';
import store from '@state/store';
import { selectAlternativeProduct, selectVariant } from '../state/productDetailSlice';
import { AlternativeProductModal } from './AlternativeProductModal';

// TODO: Replace this.
function getPhrases() {
  let phrases = {};
  try {
    phrases = JSON.parse(document.querySelector('[data-component=alternative-product]').textContent);
  } catch (e) {
    // Do nothing
  }
  // @ts-ignore
  return phrases?.Phrases;
}

const AlternativeProduct = () => {
  const dispatch = useDispatch();
  const lastAction = useSelector(state => state.lastAction);
  const isAlternativeProductModalAction = lastAction.type === 'modal/openAlternativeProductModal';
  const alternativeProduct = useSelector(state => selectAlternativeProduct(state));
  const currentVariant = useSelector(state => selectVariant(state));
  const shouldRender = (isAlternativeProductModalAction && alternativeProduct?.length > 0);
  const phrases = getPhrases();


  const handleAddToCart = async ({ alternativeVariant }) => {
    if (currentVariant){
      await removeFromCartByVariantUom(dispatch)({
        unitOfMeasureCode: currentVariant.unitOfMeasureCode,
        variantCode: currentVariant.code
      });
    }

    addToCart(dispatch)([{
      orderAmount: 1,
      variantCode: alternativeVariant.code,
      unitOfMeasureCode: alternativeVariant.unitsOfMeasure[0].code,
      // Get the AddToCartModal display correct data
      currentVariant: alternativeVariant
    }]);
  };

  return shouldRender ? (
    <AlternativeProductModal
      currentVariant={currentVariant}
      alternativeProductNumber={alternativeProduct[0]}
      onAddToCart={handleAddToCart}
      phrases={phrases}
    />
  ) : <></>;
};

export const ProvideAlternativeProductModal = ({ phrases }) => {
  return (
    <Provider store={store}>
      <AlternativeProduct phrases={phrases} />
    </Provider>
  );
};

export default ProvideAlternativeProductModal;
