/**
 * Component Navigation
 * @module Navigation
 * @param  {jQuery} $ Instance of jQuery
 * @return {Object} List of navigation methods
 */
XA.component.LoggedInNavigation = (function ($) {
  const api = {
    initialiazed: false,

    bindEvents() {
      const components = document.querySelectorAll('.component.LoggedInNavigation');
      components?.forEach(component => {
        const nodesWithEvents = component.querySelectorAll('[data-bind-event]:not([data-event-bound])') || [];
        nodesWithEvents.forEach(node => {
          const [type, handler] = node.dataset?.bindEvent?.split(':') || [];
          if (typeof this[handler] === 'function') {
            node.dataset.eventBound = true;
            node.addEventListener(type, (event) => this[handler].call(this, event));
          }
        });
      });
    },

    toggleLoggedInNavigation() {
      $('.component.LoggedInNavigation').toggleClass('is-open');
    },

    init() {
      api.bindEvents();

      $(document).on('show.bs.dropdown', (e) => {
        if (e.relatedTarget.id === 'LoggedInDropdownButton') {
          $('#LoggedInDropdownButton')
            .find('#chevron')
            .removeClass('fa-angle-down')
            .addClass('fa-angle-up');
        }
      })
      ;
      $(document).on('hide.bs.dropdown', (e) => {
        if (e.relatedTarget.id === 'LoggedInDropdownButton') {
          $('#LoggedInDropdownButton')
            .find('#chevron')
            .removeClass('fa-angle-up')
            .addClass('fa-angle-down');
        }
      });

    }
  };

  return api;
}) (jQuery, document);
XA.register('LoggedInNavigation', XA.component.LoggedInNavigation);
