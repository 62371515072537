import React from 'react';
import PropTypes from 'prop-types';
import PinchZoomPan from 'react-responsive-pinch-zoom-pan';

export const PinchZoom = ({ imgUrl, alt = '', onClose }) => {

  return (
    <div className="pinch-zoom-container">
      <button
        className="btn-square fa fa-close btn btn-cta-secondary-2"
        onClick={() => onClose()}
        style={{
          position: 'absolute',
          right: '0',
          top: '1rem',
          zIndex: 10
        }}
      >
        <span className="sr-only">Close image</span>
      </button>
      <PinchZoomPan zoomButtons={false} position="center">
        <img alt={alt} src={imgUrl} />
      </PinchZoomPan>
    </div>
  );
};


PinchZoom.propTypes = {
  alt: PropTypes.string,
  breakpointSize: PropTypes.oneOf(['small', 'large']),
  imgUrl: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};
