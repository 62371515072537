/**
 * Component Sector Navigation
 * @module Sector Navigation
 * @param  {jQuery} $ Instance of jQuery
 * @return {Object} List of Sector Navigation methods
 */
XA.component.sectorNavigation = (($) => {
  'use strict';

  const api = {
    initialized: false,

    init() {
      const $component = $('[data-component="sectorNavigation"]');
      const $dropdown = $component.find('.sector-dropdown');
      const $toggleButton = $component.find('[data-click="toggleMenu"]');

      const toggleMenu = () => {
        if($dropdown.hasClass('shown')) {
          $dropdown.removeClass('shown').addClass('hidden');
          $toggleButton.removeClass('active');

          document.body.classList.remove('noScroll');

        } else {
          $dropdown.removeClass('hidden').addClass('shown');
          $toggleButton.addClass('active');

          document.body.classList.add('noScroll');
        }
      };

      if (this.initialized) {
        return;
      }

      if(!$dropdown.length) {
        return;
      }

      $toggleButton.on('mouseover', () => {
        $dropdown.css({ 'content-visibility': 'visible' });
      });

      $toggleButton.on('click', toggleMenu);
    }

  };

  return api;
})(jQuery, document);

XA.register('sectorSwitch', XA.component.sectorNavigation);
